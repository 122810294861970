import { createSlice } from '@reduxjs/toolkit';
import mainAct from './mainAction';
import { type TimeOutTimeStampStr, SnackbarInfo } from './TypescriptProps';

const timeOutTsInit: TimeOutTimeStampStr = Date.now().toString();
const timeOutAlertTsInit: TimeOutTimeStampStr = Date.now().toString();

const snackbarInfoInit: SnackbarInfo = {
  open: false,
  message: '',
  severity: 'info',
  autoHideDuration: 6000,
};

export const timeoutReducer = createSlice({
  name: 'timeOutTs',
  initialState: timeOutTsInit,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(
      mainAct.SET_TIMEOUT_TIMESTAMP,
      (state, action) => action.payload,
    );
  },
});

export const timeoutAlertReducer = createSlice({
  name: 'timeOutAlertTs',
  initialState: timeOutAlertTsInit,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(
      mainAct.SET_TIMEOUT_ALERT_TIMESTAMP,
      (state, action) => action.payload,
    );
  },
});

export const snackbarReducer = createSlice({
  name: 'snackbarInfo',
  initialState: snackbarInfoInit,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(mainAct.SET_SNACKBAR_INFO, (state, action) => action.payload);
  },
});
