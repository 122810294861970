import role from './config.role';

const authority = {
  Home: [role.admin, role.editor, role.reader, role.developer, role.anonymous],
  Ontology: [role.admin, role.editor, role.reader, role.developer],
  Json: [role.admin, role.editor, role.reader, role.developer],
  Markdown: [role.admin, role.editor, role.reader, role.developer],
};

export default authority;
