import React from 'react';
import { Provider } from 'react-redux';

// 將 combineReducer 後的 Reducer import
import rootReducer from './rootReducer';

interface StoreProviderProps {
  children: React.ReactElement;
}

function IndexProvider({
  children,
}: Partial<StoreProviderProps>): React.JSX.Element {
  return <Provider store={rootReducer}>{children}</Provider>;
}

export default IndexProvider;
