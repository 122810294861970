import React, { useEffect } from 'react';
import {
  Container, Grid, Card, CardContent, CardActions, CardMedia, CardActionArea, Typography,
} from '@mui/material';
import {
  useAppDispatch,
  useAppSelector,
} from '../../reduxStore/utils/reduxHooks';
//
import ontologyImg from '../../images/home/ontologyGraph.png';
//
// eslint-disable-next-line import/no-cycle
import routesPage, { type IRoute } from '../../config/config.route';

// scss
import './Home.scss';
import homeAct from './homeAction';
import services from '../../app/config/services';
import { useNavigate } from 'react-router-dom';
import { routes } from '../../config/config.routeName';
import Box from '@mui/material/Box';
//
import jsonFileImg from '../../images/jsonIcon/json-file.png';
import markdownImg from '../../images/markdownIcon/markdown-svgrepo-com.svg';

type CardItem = {
  title: string;
  desc: string;
  img: string;
  path: string;
};

const cardItems:CardItem[] = [
  {
    title: 'Ontology', desc: 'Unlock project insights: Dive into the ontology with interactive graphical visualization.', img: ontologyImg, path: routes.ONTOLOGY,
  },
  {
    title: 'Markdown tool', desc: 'A Markdown Helper that converts plain text into a customized Markdown format used in Firebase firestore.', img: markdownImg, path: routes.MARKDOWN,
  },
];

function Home(): React.JSX.Element {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { pageName } = useAppSelector((state) => state.home);

  useEffect(() => {
    dispatch({
      type: homeAct.SET_PAGENAME,
      payload: 'HomePage',
    });
  }, []);

  const handleListItemClick = (card:CardItem) => (): void => {
    navigate(`/${services.getLocale()}${card.path}`);
  };

  return (
    <div className="Home">
      <Container sx={{ paddingBottom: 6 }}>
        <Box mt={6} mb={4}>
          <Typography variant="h4">
            Daoyidh Tools Hub
          </Typography>
          {/* <Typography variant="h6"> */}
          {/*  Empowering Developers and Designers for Seamless Innovation */}
          {/* </Typography> */}
        </Box>
        <Grid container spacing={2} flexDirection={{ xs: 'column', sm: 'column', md: 'row' }} justifyContent="flex-start" flexWrap="wrap">
          {cardItems.map((card) => (
            <Grid item key={card.title} xs>
              <Card key={card.title} variant="outlined" sx={{ maxWidth: 345 }} onClick={handleListItemClick(card)}>
                <CardActionArea>
                  <CardMedia
                    component="img"
                    height="140"
                    image={card.img}
                    alt={card.title}
                  />
                  <CardContent>
                    <Typography gutterBottom variant="h5" component="div">
                      {card.title}
                    </Typography>
                    <Typography variant="body2" color="text.secondary">
                      {card.desc}
                    </Typography>
                  </CardContent>
                </CardActionArea>
              </Card>
            </Grid>
          ))}
        </Grid>
      </Container>
    </div>
  );
}

export default Home;
