import { createAction } from '@reduxjs/toolkit';

import type {
  TimeOutTimeStampStr, SnackbarInfo,
} from './TypescriptProps';

const mainAct = {
  // timeout
  SET_TIMEOUT_TIMESTAMP: createAction<TimeOutTimeStampStr>(
    'SET_TIMEOUT_TIMESTAMP',
  ),
  SET_TIMEOUT_ALERT_TIMESTAMP: createAction<TimeOutTimeStampStr>(
    'SET_TIMEOUT_ALERT_TIMESTAMP',
  ),
  // snackbar alert
  SET_SNACKBAR_INFO: createAction<SnackbarInfo>('SET_SNACKBAR_INFO'),
};
export default mainAct;
