import React, { lazy } from 'react';

// components
import HomeIcon from '@mui/icons-material/Home';
import ScatterPlotIcon from '@mui/icons-material/ScatterPlot';
import JsonIcon from '../commons/components/JsonIcon/JsonIcon';
import MarkdownIcon from '../commons/components/MarkdownIcon/MakrdownIcon';
import { OverridableComponent } from '@mui/material/OverridableComponent';
import { SvgIconTypeMap } from '@mui/material/SvgIcon/SvgIcon';
// eslint-disable-next-line import/no-cycle
import Home from '../pages/Home/Home';
import authority from './config.authority';
//
import { routes } from './config.routeName';

// lazy load
const Ontology = lazy(() => import('../pages/Ontology/Ontology'));
const JsonPage = lazy(() => import('../pages/JSON/JsonPage'));
const MarkdownPage = lazy(() => import('../pages/Markdown/MarkdownPage'));

export interface IRoute {
  id: string;
  label: string;
  path: string;
  public: boolean;
  showInMenu?: boolean;
  authority: string[];
  component: React.FC<any>;
  childMenu?: IRoute[];
  icon: OverridableComponent<SvgIconTypeMap> & { muiName: string };
}

/**
 * example
 id: "route-01",
 label: "Index",
 path: "/",
 public: true,  // 是否開放此頁面
 authority: authority.Index,
 component: Index,
 childMenu: 子選單
 */

// HTML Body: URL Redirect

const routesPage: IRoute[] = [
  {
    id: 'route-01',
    label: 'Home',
    path: routes.HOME,
    public: true,
    showInMenu: true,
    authority: authority.Home,
    component: Home,
    childMenu: [],
    icon: HomeIcon,
  },
  //
  {
    id: 'route-02',
    label: 'Ontology',
    path: routes.ONTOLOGY,
    public: true,
    showInMenu: true,
    authority: authority.Ontology,
    component: Ontology,
    childMenu: [],
    icon: ScatterPlotIcon,
  },
  // JsonPage
  {
    id: 'route-03',
    label: 'JsonTool',
    path: routes.JSON,
    public: true,
    showInMenu: false,
    authority: authority.Json,
    component: JsonPage,
    childMenu: [],
    icon: JsonIcon,
  },

  {
    id: 'route-04',
    label: 'MarkdownTool',
    path: routes.MARKDOWN,
    public: true,
    showInMenu: true,
    authority: authority.Markdown,
    component: MarkdownPage,
    childMenu: [],
    icon: MarkdownIcon,
  },
];

export default routesPage;
